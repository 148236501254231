.video-header {
  position: absolute;
  text-align: center;
  width: 100vw;
  height: 100vh;
}
.video-header, .video-header video, .video-header .viewport-header {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.video-header video {
  background: brown;
  object-fit: cover;
}
.video-header .viewport-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 150%;
  line-height: 1.4;
}

body {
  margin: 0;
}

h1 {
  font-family: "Syncopate", sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 3vw;
  text-align: center;
}
h1 span {
  display: block;
  font-size: 10vw;
  letter-spacing: -0.6rem;
}

main {
  background: white;
  position: relative;
  padding: 1rem;
  margin-top: 100vh;
}
main::before {
  content: "";
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  top: -100vh;
}
main p {
  max-width: 600px;
  margin: 1rem auto;
}


.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '!';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.toot-div {
  min-height: 20vh;
  max-width: 100%;
  text-align: left;
  margin: 0px;
  padding: 0px;
  color-scheme: dark light;
    @media (prefers-color-scheme: dark) {
      background: black;
    }
}

.btn-div {
  margin-top: 5px;
  max-width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  color-scheme: dark light;
    @media (prefers-color-scheme: dark) {
      background: black;
    }
}

.links-div {
  max-width: 100%;
  text-align: center;
  margin-top: 5px;
  color-scheme: dark light;
    @media (prefers-color-scheme: dark) {
      background: black;
    }
}

@layer demo.support {
  * {
    margin: 0;
  }

  html {
    color-scheme: dark light;
    @media (prefers-color-scheme: dark) {
      background: black;
    }
  }

  main {
    padding: 0 0 0 0 !important;
  }
}
