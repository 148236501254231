.toots {
  max-width: 500px;
  list-style: none;
  padding: 0;
  margin: 0px auto;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

.toot {
  padding: 1rem;

}

/* Posting user. */
.toot .user {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  height: 46px;  /* Avatar height. */
  column-gap: 0.5rem;

  text-decoration: none;
  color: inherit;
}

.toot .avatar {
  border-radius: 4px;
}

.toot .display-name {
  font-weight: bold;
  display: block;
}

.toot .user:hover .display-name {
  text-decoration: underline;
}

.toot .username {
  display: block;
  margin-right: 1em;
  color: #999;
}

/* Boosting user is smaller and above the posting user. */
.toot .boost {
  height: 23px;
  margin-bottom: 0.25rem;
  column-gap: 0.25rem;
}

.toot .boost:before {
  content: "♺";
  font-size: 140%;
}

.toot .boost .username {
  display: none;
}

.toot .permalink {
  text-decoration: none;
  display: block;
  color: #999;
  float: right;
}

.toot .permalink:hover {
  text-decoration: underline;
}

.toot .body {
  clear: both;
  margin-top: 1em;
}

.toot .body a {
  overflow-wrap: anywhere;
}

/* Weird trick to keep the text in the page but not display it. */
.toot .body .invisible {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.toot .body .ellipsis::after {
  content: "…";
}

.toot .attachment {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 4px;
}

.toot .attachment img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
